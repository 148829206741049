import LazyLoad from "../Commons/LazyLoad";
import styles from "./ClubCard.module.css";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

const ClubCard = (props) => {
	const dark = useContext(ThemeContext).isDark;

	return (
		<Col md={6} lg={4} className={styles.cardOuter}>
			<Link
				to={`/worlds/${props.club_id}`}
				className={styles.cardLink}

			>
				<Card
					className={`${styles.Card}`}
					style={
						dark
							? {
								boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
							}
							: {
								boxShadow: "#d9d9d9 0px 2px 10px",
							}
					}
				>
					<Card.Header
						style={{
							padding: "10px 5px",
							backgroundColor: " rgb(100, 27, 43),",
							borderRadius: "20px",
							border: "0"
						}}
					>
						<div
							className={`position-absolute top-0 ps-3 pe-2 mt-1 fw-bolder ${styles.courseTypeRibbon}`}
						>
							{`Grades ${props.grades}`}
						</div>

						<div
							className={styles.cardHeader}
							style={{
								height: "200px",
								padding: "0",
								margin: "0",
								borderRadius: "20px",
							}}
						>
							<LazyLoad
								src={props.img_url}
								alt="club image"
								classes={styles.banner}
								wrapper="image-lazyload"
							/>
						</div>
					</Card.Header>
					<Card.Body style={{ paddingTop: "20px" }}>
						<p className="mb-2 mt-1"> {props.subject}</p>
						<Card.Title style={{ fontWeight: "600" }}>{props.title}</Card.Title>
						<Card.Text style={{ color: "var(--fontColor-eo)" }}>
							{props.description}
						</Card.Text>
						<p className="m-0 mt-4 p-0 d-flex align-items-center">
							<img
								src={props.instructorImg}
								alt="Abhishek"
								style={{
									width: "40px",
									height: "40px",
									borderRadius: "50%",
									display: "flex"
								}}
							/>
							<span className="ps-2 fw-bold text-info">{props.instructor}</span>
						</p>
					</Card.Body>
				</Card>
			</Link>
		</Col>
	);
};

export default ClubCard;
