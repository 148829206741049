import { Link } from "react-router-dom";
import LazyLoad from "../../Commons/LazyLoad";
import { capitalizeName } from "../../utils";
import moment from "moment";

const EventCard = (props) => {
	let data = props.data;
	let featured_image = data.featured_image;
	let event_url = "/room/" + data.id;
	let full_date = moment(data.start_time_ts).format("LLL");

	return (
		<div className="experience-snippet">
			<div
				className="experience-snippet-inner"
				style={
					props.dark
						? {
							boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
							borderRadius: "30px",
							background: "var(--background_primary)",
						}
						: {
							boxShadow: "#d9d9d9 0px 2px 10px",
							borderRadius: "30px",
							background: "var(--background_primary)",
						}
				}
			>
				<div className="snippet-image">
					<Link to={event_url}>
						<LazyLoad
							src={
								data?.mentor?.avatar_url
									? data?.mentor?.avatar_url
									: featured_image
							}
							alt={data.title}
							classes="snippet-img-snippets"
						/>
					</Link>
				</div>
				<div className="snippet-content snippet-content-webinar">
					<div className="snippet-content-hero">
						<div className="snippet-content-head">
							<div
								className="snippet-event-type world-event"
								style={{
									background: "var(--background_secondary)",
								}}
							>
								{data.room_type
									? String(data?.room_type).toUpperCase()
									: "WORKSHOP"}
							</div>
							{/* <div>
								<em>{grade_text}</em>
							</div> */}
						</div>
						<div className="snippet-content-main">
							<div>
								<Link to={event_url}>
									<h5 className="">{data?.title}</h5>
								</Link>
								<div className="snippet-content-main_sub-details">
									<p>
										By{" "}
										{capitalizeName(
											`${data?.mentor?.first_name} ${data?.mentor?.last_name || ""
											}`
										)}
									</p>
									<div>
										<p className="duration-in-snippet">{full_date}</p>
									</div>
								</div>
							</div>

							<button
								className="py-2 px-4 text-white"
								disabled={props.sneakPeek}
								style={props.sneakPeek ? { cursor: "not-allowed" } : {}}
								onClick={() => props.history.push(event_url, { path: "worlds" })}
							>
								View
							</button>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventCard;
