import { Link } from "react-router-dom"
import React, { useState, useEffect, useContext } from "react"
import { Col, Row, Form, Spinner } from "react-bootstrap"
import LazyLoad from "../Commons/LazyLoad";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import { ThemeContext } from "../../context/ThemeContext";
import moment from "moment";

// getting the next subscription date
const nextSubDate = (date) => {
    const currentDate = moment().startOf('day');
    const originalDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    let newDate = originalDate.add(30, 'days');

    while (newDate.isBefore(currentDate)) {
        newDate = newDate.add(30, 'days');
    }

    const formattedDate = newDate.format('MMM DD, yyyy');
    return formattedDate;
};

// getting free trial end date
const freeTrailEndDate = (subDate, totalFreeTrialDays) => {
    const subStartDate = moment(subDate, 'YYYY-MM-DD').startOf('day');
    const trialEndDate = subStartDate.add(totalFreeTrialDays, 'days');
    const formattedDate = trialEndDate.format('MMM DD, yyyy');
    return formattedDate;
};

const PastWorlds = (props) => {
    const dark = useContext(ThemeContext).isDark;
    const [worlds, setWorlds] = useState([])
    const [loading, setLoading] = useState(true)
    const access_token = props.access_token

    useEffect(() => {
        const fetchWorlds = async () => {
            try {
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + props.access_token);

                let requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(`${url}bookings`, requestOptions);
                const data = await response.json();
                setWorlds(data.data.results);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
        fetchWorlds();
    }, [])

    const WorldSkeleton = () => {
        return (
            <Col xs={12} md={6} className="my-3">
                <div className="experience-snippet-dashboard" style={{ background: "var(--background_secondary)" }}>
                    <div className="snippet-dashboard-img">
                        <Skeleton height={170} width="100%" />
                    </div>
                    <div className="exp-snippet-dashboard-texts">
                        <h3 className="snippet-dashboard-text">
                            <Skeleton height={25} width={150} />
                        </h3>
                        <p className="snippet-with-dashboard-text">
                            <Skeleton height={20} width={150} />
                        </p>
                        <div className="snippet-dashboard-last d-flex justify-content-between">
                            <Skeleton height={30} width={80} />
                            <Skeleton height={30} width={80} />
                        </div>
                    </div>
                </div>

            </Col>
        )
    }

    const confPopup = (world) => {
        var conf_popup = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <ConfPopuupContent world={world} />
            </React.StrictMode>,
            conf_popup
        );
        swal({
            content: conf_popup,
            buttons: false,
            className: "swal-payment-conf2",
            closeOnClickOutside: true,
        });
    };

    const confPopup2 = () => {
        var conf_popup2 = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <ConfPopuupContent2 />
            </React.StrictMode>,
            conf_popup2
        );
        swal({
            content: conf_popup2,
            buttons: false,
            className: "swal-payment-conf",
            closeOnClickOutside: true,
        });
    };

    const ConfPopuupContent = (props) => {
        const [loading2, setLoading2] = useState(false) // unsubscribe world
        const [formData, setFromData] = useState({
            reason: "Too expensive",
            additionalComments: ""
        })

        const options = [
            { reason: "Too expensive", value: "Too expensive" },
            { reason: "Not finding enough value", value: "Not finding enough value" },
            { reason: "Harassment/Spam", value: "Harassment/Spam" },
            { reason: "Not much happening in the club", value: "Not much happening in the club" },
            { reason: "Others", value: "Others" },
        ]

        // Pushing event to moengage
        const pushMoengageEvent = () => {
            window.Moengage.track_event("CancelWorldSubscription", {
                world_title: props.world.world.title,
                cost: props.world.world.fee,
                child_first_name: props?.world?.uuid?.student?.first_name,
                Subscription_end_date: props?.world?.world?.free_world ? "Free World" : props?.world?.world?.free_trial ? freeTrailEndDate(props?.world?.active_since, props?.world?.world?.free_trial_days) : nextSubDate(props?.world?.active_since)
            });
        }

        const cancelWorldSubscription = async (world) => {
            if (loading2) return;
            try {
                setLoading2(true)
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${world.world.id}/subscribe/cancel`;
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: JSON.stringify({
                        reason: formData.reason,
                        comments: formData.additionalComments
                    })
                };

                const response = await fetch(`${url}`, requestOptions);
                const data = await response.json();

                if (data.success) {
                    setWorlds(worlds.filter((w) => w.world.id !== world.world.id));
                    pushMoengageEvent();
                    swal.close();
                    confPopup2();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading2(false)
            }
        }

        return (
            <div className="p-3">
                <div>
                    <h3 className="my-2 mb-3">
                        Are you sure you want to unsubscribe?
                    </h3>
                    <p className="mb-4">
                        This will remove your access from {" "}
                        <span className="fw-bold">
                            {props.world.world.title}
                        </span> {" "}
                        and all the data will be lost.
                    </p>
                    <h5 className="mb-3">Reason for unsubscribing ?</h5>
                    <div>
                        <img
                            src="/icons/arrow-down.png"
                            alt="down-arrow"
                            style={
                                dark ? {
                                    width: "45px",
                                    height: "48px",
                                    position: "absolute",
                                    right: "20px",
                                    filter: "invert(1)"
                                } : {
                                    width: "45px",
                                    height: "50px",
                                    position: "absolute",
                                    right: "13px",
                                }
                            }
                        />
                        <Form.Select
                            size="lg"
                            onChange={(e) => setFromData({
                                ...formData,
                                reason: e.target.value
                            })}
                        >
                            {
                                options.map((option) => {
                                    return (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.reason}
                                        </option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </div>

                <div>
                    <h5 className="my-3">Additional comments</h5>
                    <Form.Control
                        as="textarea"
                        placeholder="10 to 500 characters"
                        onChange={(e) => {
                            setFromData({
                                ...formData,
                                additionalComments: e.target.value
                            })
                        }}
                    />
                    <p className="p-2">
                        On unsubscribing, you will continue to have access to this club until the end of your monthly subscription.
                    </p>
                </div>

                <div className="d-flex justify-content-end mt-3">
                    <button
                        type="button"
                        className="px-3 mx-3 py-1 my-2 text-white"
                        onClick={() => swal.close()}
                        style={{ fontSize: "14px" }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="px-3 py-1 my-2"
                        onClick={() => cancelWorldSubscription(props.world)}
                        style={{ background: "transparent", border: "2px solid var(--border)", fontSize: "14px" }}
                    >
                        {loading2 ? (<Spinner animation="border" variant="danger" />) : "Confirm"}
                    </button>
                </div>
            </div>
        );
    };

    const ConfPopuupContent2 = () => {
        return (
            <div>
                <h3 className="mt-2">
                    You will be missed 😞
                </h3>
                <p className="p-2">
                    You have successfully unsubscribed from this world. However, you will continue to have access to the space until the end of your monthly subscription.
                    For any concerns, reach out to us via email at hello@helloworlds.co.
                </p>
                <button className="px-3 py-1 my-2 text-white" onClick={() => swal.close()}>
                    Okay
                </button>
            </div>
        );
    };

    return (
        <Row >
            {
                loading ? (
                    <SkeletonTheme baseColor="gray" highlightColor="white">
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                        <WorldSkeleton />
                    </SkeletonTheme>
                ) :
                    worlds.length > 0 ? worlds.map((world) => {
                        return (
                            <Col xs={12} md={6} className="my-3" key={world?.world?.id}>
                                <div
                                    className="experience-snippet-dashboard"
                                    style={
                                        dark
                                            ? {
                                                boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
                                                background: "var(--background_primary)"
                                            }
                                            : {
                                                boxShadow: "#d9d9d9 0px 2px 10px",
                                                background: "var(--background_primary)"
                                            }
                                    }
                                >
                                    <Link to={`/worlds/${world?.world?.id}/activity`}>
                                        <LazyLoad
                                            src={world?.world?.image}
                                            alt="snippet-dashboard-img"
                                            classes="snippet-dashboard-img"
                                        />
                                    </Link>
                                    <div className="exp-snippet-dashboard-texts">
                                        <h3 className="snippet-dashboard-text">
                                            {world?.world?.title?.length > 20 ? world?.world?.title?.slice(0, 20) + "..." : world?.world?.title}

                                        </h3>
                                        <p className="snippet-with-dashboard-text">
                                            With {world?.world?.uuid?.user?.first_name} {world?.world?.uuid?.user?.last_name}
                                        </p>
                                        <div className="snippet-dashboard-last d-flex justify-content-between">
                                            <button
                                                className=" py-1 px-3 fw-bold snippet-dashboard-attend"
                                                title="Cancel Subscription"
                                                onClick={() => confPopup(world)}
                                                id="exit-world"
                                            >
                                                EXIT
                                            </button>

                                            <button
                                                className="py-1 px-3 snippet-dashboard-attend"
                                                title="Need Help"
                                                onClick={() => window.open("https://api.whatsapp.com/send?phone=919043408421&text=Hello,%20I%20have%20an%20issue%20to%20raise", "_blank")}
                                            >
                                                Help {" "}
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>

                                            </button>
                                        </div>
                                        <div className="py-3 px-1">
                                            {
                                                world?.world?.free_trial === 1 ? (
                                                    <p className="snippet-with-dashboard-text">
                                                        Free trial ends on <br /> <span className="text-info">
                                                            {freeTrailEndDate(world?.active_since, world?.world?.free_trial_days)}
                                                        </span>
                                                    </p>
                                                ) : world?.world?.free_world === 1 ? (
                                                    <p className="snippet-with-dashboard-text">
                                                        This is a free World, you won't be charged ever!
                                                    </p>
                                                ) : (
                                                    <p className="snippet-with-dashboard-text">
                                                        Next subscription of <span className="text-info">₹{world?.world?.fee}</span> on <br />
                                                        <span className="text-info">{nextSubDate(world?.active_since)}</span>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : (
                        <div className="no-upcoming-past-recording">
                            <p className="bold-no-past-recording mb-3">No Worlds joined yet. You can cancel subscription to any of the joined Worlds here.</p>
                            <Link to={"/worlds"}>
                                <button
                                    className="py-2 px-3"
                                    type="submit"
                                >
                                    Explore Worlds
                                </button>
                            </Link>
                        </div>
                    )
            }
        </Row>
    )
}

export default PastWorlds