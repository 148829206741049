import { Link } from "react-router-dom";
import LazyLoad from "../Commons/LazyLoad";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";

const JoinedClubs = (props) => {
	const dark = useContext(ThemeContext).isDark;
	const [clubs, setClubs] = useState([]);
	const [loading, setLoading] = useState(false);
	let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer " + props.access_token);

	let requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	const fetClubs = async () => {
		try {
			setLoading(true);
			const response = await fetch(`${url}bookings`, requestOptions);
			const data = await response.json();
			setClubs(data.data.results);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	}

	useEffect(() => {
		fetClubs();
	}, [])

	let scrollBox = null;
	const scrollSpeed = 5;
	const scrollLength = 200;
	const scrollSteps = 2;
	const getCarouselScroll = () => {
		scrollBox = document.getElementById("custom-carousel");
	};

	const scrollRightBtn = () => {
		sideScroll(scrollBox, "right", scrollSpeed, scrollLength, scrollSteps);
	};

	const scrollLeftBtn = () => {
		sideScroll(scrollBox, "left", scrollSpeed, scrollLength, scrollSteps);
	};

	function sideScroll(element, direction, speed, distance, step) {
		let scrollAmount = 0;
		var slideTimer = setInterval(function () {
			if (direction === "left") {
				element.scrollLeft -= step;
			} else {
				element.scrollLeft += step;
			}
			scrollAmount += step;
			if (scrollAmount >= distance) {
				window.clearInterval(slideTimer);
			}
		}, speed);
	}

	const LoadingSkeleton = () => {
		return (
			<div
				className="col-12 custom-carousel py-3 ps-4"
				id="custom-carousel"
				onLoad={getCarouselScroll}
				style={{ borderRadius: "20px", }}
			>
				<SkeletonTheme baseColor="gray" highlightColor="white">
					<div className=" me-3 rounded-4 p-0" style={{ background: "var(--background_secondary)" }}>
						<Skeleton height={200} width={250} className="rounded-4" />
						<p className="m-0 ps-2">
							<Skeleton height={20} width={200} />
						</p>
						<p className="m-0 ps-2">
							<Skeleton height={15} width={100} />
						</p>
						<p className="m-0 ps-2 pb-2">
							<Skeleton height={28} width={70} />
						</p>
					</div>
					<div className="me-3 rounded-4" style={{ background: "var(--background_secondary)" }}>
						<Skeleton height={200} width={250} className="rounded-4" />
						<p className="m-0 ps-2">
							<Skeleton height={20} width={200} />
						</p>
						<p className="m-0 ps-2">
							<Skeleton height={15} width={100} />
						</p>
						<p className="m-0 ps-2 pb-2">
							<Skeleton height={28} width={70} />
						</p>
					</div>
					<div className=" px-2 me-3 rounded-4" style={{ background: "var(--background_secondary)" }}>
						<Skeleton height={200} width={250} className="rounded-4" />
						<p className="m-0 ps-2">
							<Skeleton height={20} width={200} />
						</p>
						<p className="m-0 ps-2">
							<Skeleton height={15} width={100} />
						</p>
						<p className="m-0 ps-2 pb-2">
							<Skeleton height={28} width={70} />
						</p>
					</div>
				</SkeletonTheme>
			</div>

		);
	};

	return (
		<div
			className="upcoming-exp-dashboard-div"
			style={
				dark
					? {
						boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
						background: "var(--background_primary)",
					}
					: {
						boxShadow: "#d9d9d9 0px 2px 10px",
						background: "var(--background_primary)",
					}
			}
		>
			<h2>Your Worlds</h2>
			<div className="carousel-parent-dashboard">

				{
					loading ? (

						<SkeletonTheme baseColor="gray" highlightColor="white">
							<Skeleton wrapper={LoadingSkeleton} count={1} />
						</SkeletonTheme>
					) :
						clubs.length ? (
							<div
								className="col-12 custom-carousel py-3 "
								id="custom-carousel"
								onLoad={getCarouselScroll}
							>
								{clubs.map((club) => (
									<div className="col-10 col-sm-8 col-md-8 col-lg-7 col-xl-5" key={club.world.id}>
										<WithStyles club={club} />
									</div>
								))}
							</div>
						) : (
							<div className="no-upcoming-dashboard">
								<LazyLoad
									src="/icons/calendar.png"
									classes="calendar-dashboard"
									alt="calendar-dashboard"
								/>
								<p className="no-bookings-yet">
									No clubs joined yet
								</p>
								<button className="dashboard-browse-btn">
									<Link to="/worlds">Browse</Link>
								</button>
							</div>
						)}
			</div>
		</div>
	);
};


const WithStyles = ({ club }) => {
	const dark = useContext(ThemeContext).isDark;
	return (
		<div className="experience-snippet-dashboard-parent" >
			<div
				className="experience-snippet-dashboard club-dash"
				style={
					dark
						? {
							boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
							background: "var(--background_primary)",
						}
						: {
							boxShadow: "#d9d9d9 0px 2px 10px",
							background: "var(--background_primary)",
						}
				}
			>
				<Link to={`/worlds/${club.world.id}/activity`}>
					<LazyLoad
						src={club?.world?.image}
						alt="snippet-dashboard-img"
						classes="snippet-dashboard-img"
					/>
				</Link>
				<div className="exp-snippet-dashboard-texts">
					<h3 className="snippet-dashboard-text">
						{club?.world?.title?.length > 40 ? club?.world?.title?.slice(0, 40) + "..." : club?.world?.title}
					</h3>
					<p className="snippet-with-dashboard-text">
						With {club?.world?.uuid?.user?.first_name}
					</p>
					<div className="snippet-dashboard-last pb-2">
						<Link
							to={`/worlds/${club.world.id}/activity`}
						>
							<button className="snippet-dashboard-attend">
								Enter
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default JoinedClubs;
