import { useState, useEffect } from "react";
import css from "./UpcomingLiveRooms.module.css";
import { Link } from "react-router-dom";
import { dateConverter } from "../utils";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LazyLoad from "../Commons/LazyLoad";
import { Spinner } from "react-bootstrap";

const UpcomingLiveRooms = ({ key, student_id, access_token, student_uuid, dark }) => {
	const [upcoming, setUpcoming] = useState([]);
	const [loading, setLoading] = useState(true);
	const [redirectLoading, setRedirectLoading] = useState(false);

	/**
 * Getting Zoom link and redirecting to zoom link
 * @param {number} session_id
 */
	const redirectToZoom = (id) => {
		if (redirectLoading) return;
		setRedirectLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + access_token);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({ random: "random" });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${id}}/register`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then((result) => {
				setRedirectLoading(false);
				if (result.success === true) {
					window.open(result.data.zoom_link, "_blank");
				}
			});
	};

	useEffect(() => {
		if (student_id !== null) {
			setLoading(true);

			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};

			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/dashboard`;

			fetch(url, requestOptions)
				.then((res) => res.json())
				.then((result) => {
					setUpcoming(result.data.results);
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	}, [access_token, student_id]);

	// const getClassTypeAndIcon = (str) => {
	// 	const type = str.toUpperCase();
	// 	if (type.includes("WEBINAR")) {
	// 		return (
	// 			<>
	// 				<img src="/icons/webinar.svg" alt="webinar" />
	// 				{type}
	// 			</>
	// 		);
	// 	} else if (type.includes("CHAT")) {
	// 		return (
	// 			<>
	// 				<img src="/icons/chat.svg" alt="chat" />
	// 				{type}
	// 			</>
	// 		);
	// 	} else if (type.includes("WORKSHOP")) {
	// 		return (
	// 			<>
	// 				<img src="/icons/workshop.svg" alt="workshop" />
	// 				{type}
	// 			</>
	// 		);
	// 	} else {
	// 		return (
	// 			<>
	// 				<img src="/icons/quiz.svg" alt="quiz" />
	// 				{type}
	// 			</>
	// 		);
	// 	}
	// };

	const LoadingSkeleton = () => {
		return (
			<li className={`${css.content} mb-2`}>
				<div>
					<Skeleton width="30%" height={16} />
					<Skeleton width="75%" height={30} />
					<Skeleton width="40%" height={16} />
				</div>
				<Skeleton width={80} height={40} className="rounded-pill" />
			</li>
		);
	};

	return (
		<div
			className="upcoming-exp-dashboard-div"
			style={
				dark
					? {
						boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
						background: "var(--background_primary)",
					}
					: {
						boxShadow: "#d9d9d9 0px 2px 10px",
						background: "var(--background_primary)",
					}
			}
		>
			<h2 className={css.heading}>Your Upcoming Live Rooms</h2>
			<ul className={css.upcomingContent}>
				{loading ? (
					<SkeletonTheme baseColor="gray" highlightColor="white">
						<Skeleton wrapper={LoadingSkeleton} count={2} />
					</SkeletonTheme>
				) : (
					<>
						{upcoming.map((room) => {
							return (
								<li key={room?.session_id} className={css.content}>
									<div>
										<section
											className={css.icons + " world-event"}
											style={{
												background: "var(--background_secondary)",
												width: "fit-content",
												marginLeft: "-8px",
											}}
										>
											{(room?.room_type || "CHAT/AMA").toUpperCase()}
										</section>
										<h5>{room?.session_title}</h5>
										<p className={css.author_timing + " pb-2"} style={{ fontSize: "15px" }}>
											<span>
												<Link to={`/worlds/${room?.world_id}/activity`}>
													{room?.world_name}
												</Link>
											</span>
										</p>
										{
											window.innerWidth < 768 && (
												<p style={{ fontSize: "15px" }}>
													{/* <i class="fa fa-clock-o"></i>{" "} */}
													{moment(room?.start_time_ts).format("h:mm a, D MMM, YYYY")}
												</p>
											)
										}
									</div>
									<div className={css.join}>
										<a
											href={room?.zoom_link}
											onClick={(e) => {
												e.preventDefault();
												redirectToZoom(room?.session_id)
											}}
										>
											<button
												className="dashboard-browse-btn"
												style={{ borderRadius: "10px" }}
												disabled={redirectLoading}
											>
												{
													redirectLoading ? (
														<Spinner animation="border" size="sm" />
													) : (
														"Join"
													)
												}
											</button>
										</a>
										{
											window.innerWidth > 768 && (
												<p className="mt-3" style={{ fontSize: "15px" }}>
													{/* <i class="fa fa-clock-o"></i>{" "} */}
													{moment(room?.start_time_ts).format("h:mm a, D MMM, YYYY")}
												</p>
											)
										}
									</div>
								</li>
							);
						})}

						{!upcoming.length && (
							<div className="no-upcoming-dashboard">
								<LazyLoad
									src="/icons/calendar.png"
									classes="calendar-dashboard"
									alt="calendar-dashboard"
								/>
								<p className="no-bookings-yet">
									You don't have any upcoming sessions from your clubs.{" "}
								</p>
								<button className="dashboard-browse-btn">
									{/* <Link to="/learn">Browse</Link> */}
									<Link to="/worlds">Browse</Link>
								</button>
							</div>
						)}
					</>
				)}
			</ul>
		</div>
	);
};

export default UpcomingLiveRooms;
